<template>
  <div>
    <div v-if="Object.keys(groupedHotels).length" class="history-page__visits">
      <div
        v-for="(visits, year) in groupedHotels"
        :key="year"
        class="history-page__visits"
      >
        <div class="history-page__title">{{ year }} г.</div>
        <div
          v-for="(visit, index) in visits"
          :key="index"
          class="history-page__visit visit"
          @click="onCheckOrders(visit.slug)"
        >
          <img
            class="visit__image"
            :src="require(`@/assets/images/${visit.image}.png`)"
            alt=""
          />
          <div class="visit__inner">
            <img
              class="visit__icon"
              :src="require(`@/assets/images/${visit.icon}.png`)"
              alt=""
            />
            <div class="visit__info">
              <div class="visit__name">{{ visit.name }}</div>
              <div class="visit__date">
                {{ reformatDate(visit.checkIn) }}-{{
                  reformatDate(visit.checkOut)
                }}
              </div>
            </div>
            <div v-if="visit.orders.length" class="visit__label">
              {{ visit.orders.length }} заказов
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="history-page__text">
      Вы еще не пользовались нашим приложением
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { ref } from 'vue';
  import { visits } from '@/config';
  import { Visit } from '@/models';
  import router from '@/router';
  import { reformatDate } from '@/helper';

  const hotelVisits = ref(async () => {
    return new Promise<Visit[]>(resolve => {
      setTimeout(() => {
        resolve(visits);
      }, 1000);
    });
  });

  const hotels = ref(await hotelVisits.value());
  const groupedHotels = ref(groupVisitsByYear(hotels.value));

  function onCheckOrders(slug: string) {
    router.push({
      name: 'HistoryOrderPage',
      params: { slug },
    });
  }

  function groupVisitsByYear(visits: Visit[]) {
    return visits.reduce((acc: Record<number, Visit[]>, visit) => {
      const year = visit.checkIn.getFullYear();
      if (!acc[year]) {
        acc[year] = [];
      }
      acc[year].push(visit);
      return acc;
    }, {});
  }
</script>

<style lang="scss" scoped>
  .history-page {
    &__visits {
      display: flex;
      flex-direction: column;

      width: 100%;

      gap: 5px;
    }

    &__title {
      margin: 10px 0;

      color: var(--gray-black);

      @include toRem(line-height, 24px);
    }

    .visit {
      position: relative;

      overflow: hidden;

      width: 100%;
      height: 225px;

      border-radius: 25px;

      &__image {
        position: absolute;

        width: 100%;
        height: 100%;

        object-fit: cover;
      }

      &__inner {
        position: relative;
        z-index: 100;
        top: 10px;
        left: 10px;

        display: flex;
        overflow: hidden;
        flex-direction: column;
        justify-content: space-between;

        width: 50%;
        min-height: 70%;
        padding: 10px;

        border-radius: 25px;
        background: white;
      }

      &__icon {
        margin-bottom: 10px;

        border-radius: 50%;

        @include setSquareSize(60px);
        object-fit: cover;
      }

      &__name {
        font-weight: 600;

        @include toRem(line-height, 19.5px);
      }

      &__date {
        margin-top: 5px;

        color: var(--gray-black);
      }

      &__label {
        position: absolute;
        z-index: 100;
        top: 0;
        right: 0;

        display: flex;
        align-items: center;

        min-width: 90px !important;
        height: 37px;
        padding: 5px 14px;

        color: var(--gray-black);
        border-top-right-radius: 25px;
        border-bottom-left-radius: 25px;
        background: #eaeaea70;

        font-weight: 600;

        backdrop-filter: blur(3px);
      }
    }
  }
</style>
