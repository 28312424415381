import { Cart, Order } from '@/models';
import api from '@/utils/axios';
import { AxiosResponse } from 'axios';

export default class CartService {
  static async getServiceFreeTime(
    id: string,
    day: Date,
  ): Promise<AxiosResponse<number[]>> {
    return api.post<number[]>('/service-category/service/day-schedule', {
      id,
      day,
    });
  }

  static async getOrders(): Promise<AxiosResponse<Order[]>> {
    return api.get<Order[]>('/order');
  }

  static async createOrder(order: Cart): Promise<AxiosResponse<Cart>> {
    const formattedOrder = order.items.map(item => ({
      ...item,
      service: item.service.id,
    }));
    return api.post<Cart>('/order', { items: formattedOrder });
  }
}
