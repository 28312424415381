<template>
  <div class="wrapper order-page">
    <div class="order-page__header">
      <app-button
        class="header__button"
        theme="gray"
        is-square
        @click="onReturnBack"
      >
        <img alt="" src="@/assets/icons/SVG/arrow.svg" />
      </app-button>
      <div class="order-page__logo"></div>
    </div>
    <img
      :src="require(`@/assets/images/${image}.png`)"
      alt=""
      class="order-page__picture"
    />
    <div class="order-page__block">
      <div class="order-page__icon">
        <img :src="require(`@/assets/images/${icon}.png`)" alt="" />
      </div>
      <div class="order-page__name">{{ name }}</div>
      <div class="order-page__date">{{ checkIn }}-{{ checkOut }}</div>
      <div class="order-page__title">История заказов</div>
    </div>
    <div v-if="orders[0]" class="order-page__inner">
      <div v-for="(orders, date) in sortedOrders" :key="date">
        <div
          v-for="order in orders"
          :key="order.id"
          class="order-page__item order"
        >
          <div class="order-page__date">
            {{ order.createdAt.toLocaleDateString() }}
          </div>
          <div
            v-for="{ service } in order.items"
            :key="service.id"
            class="order-page__service service"
          >
            <div class="service__icon">
              <img src="@/assets/images/spa1.png" alt="" />
            </div>
            <div class="service__info">
              <div class="service__name">
                {{ service.translations.ru_RU.name }}
              </div>
              <div
                :class="[
                  'service__cost',
                  { order__cost_free: service.price === 0 },
                ]"
              >
                {{
                  service.price === 0
                    ? 'Бесплатно'
                    : `${service.price} руб/${service.meta?.duration}`
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="order-page__inner">
      <div class="order-page__text">Тут пока пусто</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { computed, ref } from 'vue';
  import { visits } from '@/config';
  import { Order, Visit } from '@/models';
  import { reformatDate } from '@/helper';
  import router from '@/router';
  import { useRoute } from 'vue-router';

  const route = useRoute();
  const data = ref(async () => {
    return new Promise<Visit>(resolve => {
      setTimeout(() => {
        resolve(visits.find(value => value.slug === route.params.slug));
      }, 500);
    });
  });

  const name = ref((await data.value()).name);
  const checkIn = ref(reformatDate((await data.value()).checkIn));
  const checkOut = ref(reformatDate((await data.value()).checkOut));
  const orders = ref((await data.value()).orders);
  const image = ref((await data.value()).image);
  const icon = ref((await data.value()).icon);

  const groupedOrders = ref(
    (await data.value()).orders.reduce<Record<string, Order[]>>(
      (acc, order) => {
        const date = order.createdAt.toLocaleDateString();
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(order);
        return acc;
      },
      {},
    ),
  );

  function onReturnBack() {
    router.push({
      name: 'WelcomePage',
    });
  }

  const sortedOrders = computed(() => {
    return Object.entries(groupedOrders.value)
      .sort(([dateA], [dateB]) => {
        const parsedDateA = Date.parse(dateA.split('.').reverse().join('-'));
        const parsedDateB = Date.parse(dateB.split('.').reverse().join('-'));
        return parsedDateA - parsedDateB;
      })
      .reduce(
        (acc, [date, orders]) => {
          acc[date] = orders;
          return acc;
        },
        {} as Record<string, Order[]>,
      );
  });
</script>

<style lang="scss" scoped>
  .order-page {
    position: relative;

    align-items: center;
    flex-direction: column;

    padding: 0 !important;

    &__header {
      z-index: 1001;

      display: flex;
      align-items: center;

      width: 100%;
      padding: 8px;
    }

    &__logo {
      width: 100px;
      height: 20px;
      margin-left: 12px;

      background: url('@/assets/icons/SVG/logo-hotelkey.svg') no-repeat center /
        cover;
    }

    &__block {
      position: relative;
      top: -100px;

      display: flex;
      align-items: center;
      flex-direction: column;

      width: 100%;

      border-radius: 25px 25px 0px 0px;
      background-color: white;
    }

    &__picture {
      position: relative;

      overflow: hidden;

      width: 100%;
      height: 220px;
      margin-top: 4px;

      border-radius: 30px;

      object-fit: cover;
    }

    &__icon {
      position: absolute;
      top: -30px;
      left: 50%;

      overflow: hidden;

      transform: translateX(-50%);

      border: 5px white solid;
      border-radius: 50%;

      @include setSquareSize(75px);

      img {
        @include setSquareSize(100%);
        object-fit: cover;
      }
    }

    &__name {
      margin-top: 54px;

      font: {
        @include toRem(size, 23px);
        weight: 600;
      }

      @include toRem(line-height, 28px);
    }

    &__date {
      color: var(--gray-black);

      font: {
        family: Montserrat;
        weight: 500;
      }

      @include toRem(line-height, 24px);
    }

    &__actions {
      display: flex;

      margin-top: auto;

      gap: 5px;
    }

    &__action {
      img {
        @include filter-blue;
        @include setSquareSize(20px);
      }
    }

    &__title {
      margin-top: 30px;

      align-self: flex-start;

      @include subtitle-text;
    }

    &__inner {
      position: relative;
      top: -100px;

      display: flex;
      flex-direction: column;

      width: 100%;

      .order-page__date {
        margin: 8px 0;
      }
    }

    .order {
      display: flex;
      align-items: left;
      flex-direction: column;

      margin-bottom: 10px;

      gap: 7px;
    }

    .service {
      position: relative;

      display: flex;
      align-items: center;

      width: 100%;
      padding: 5px 0;
      &__icon {
        overflow: hidden;

        border-radius: 50%;

        @include setSquareSize(60px);

        img {
          @include setSquareSize(100%);
          object-fit: cover;
        }
      }

      &__info {
        display: flex;
        flex-direction: column;

        margin-left: 10px;
      }

      &__name {
        font-weight: 600;
      }

      &__cost {
        color: var(--gray-black);

        font: {
          family: Montserrat;
          weight: 500;
        }

        @include toRem(line-height, 24px);
      }

      &::after {
        position: absolute;
        bottom: -5px;

        width: calc(100% - 10px);
        height: 3px;
        margin: 0 5px;

        content: '';

        border-radius: 35px;
        background: var(--gray-main);
      }

      &:last-of-type::after {
        display: none;
      }
    }

    &__text {
      margin-top: 130px;
      text-align: center;

      color: var(--gray-black);

      line-height: 22px;

      @include toRem(font-size, 18px);
    }
  }
</style>
