<template>
  <div class="wrapper profile-page">
    <div class="profile-page__header">
      <app-button
        class="profile-page__button"
        theme="gray"
        is-round
        is-square
        @click="onReturnBack"
      >
        <img class="button__icon" src="@/assets/icons/SVG/arrow.svg" alt="" />
      </app-button>
      <app-button class="profile-page__button" theme="gray" is-round is-square>
        <img src="@/assets/icons/SVG/edit.svg" alt="" />
      </app-button>
    </div>
    <div class="profile-page__title">Мой заказ</div>
    <div class="profile-page__subtitle">Валерия</div>
    <div class="profile-page__subtitle profile-page__subtitle_gray">
      +7 (978) 342 70-25
    </div>
    <!-- <app-button
      class="profile-page__button_large"
      theme="gray"
      text="Настройка уведомлений"
    >
      <img
        class="button__icon"
        src="@/assets/icons/SVG/notifications.svg"
        alt=""
      />
      <img
        class="icon_right"
        src="@/assets/icons/SVG/arrow-simple.svg"
        alt=""
      />
    </app-button> -->
    <app-button
      class="profile-page__button_large"
      theme="gray"
      text="Привязать карту"
    >
      <img src="@/assets/icons/SVG/credit-card.svg" alt="" />
      <img class="icon_right" src="@/assets/icons/SVG/plus.svg" alt="" />
    </app-button>
    <div class="profile-page__subtitle profile-page__subtitle_info">
      Информация
    </div>
    <swiper-container
      class="profile-page__cards"
      slides-per-view="auto"
      :space-between="5"
      :free-mode="true"
    >
      <swiper-slide class="profile-page__card">
        <div class="profile-page__icon">
          <img src="@/assets/icons/SVG/message-chat-square.svg" alt="" />
        </div>
        <span>Ответы на вопросы</span>
      </swiper-slide>
      <swiper-slide class="profile-page__card">
        <div class="profile-page__icon">
          <img src="@/assets/icons/SVG/task-list.svg" alt="" />
        </div>
        <span>Правила и соглашения</span>
      </swiper-slide>
      <swiper-slide class="profile-page__card">
        <div class="profile-page__icon">
          <img src="@/assets/icons/SVG/lock.svg" alt="" />
        </div>
        <span>Политика конфиденциальности</span>
      </swiper-slide>
    </swiper-container>
    <app-button
      class="profile-page__logout"
      theme="gray"
      text="Выйти"
      data-cy="logout"
      @click="onLogout"
    />
    <app-button
      class="profile-page__delete"
      text="Удалить аккаунт"
      data-cy="delete"
    />
  </div>
</template>

<script lang="ts" setup>
  import { onMounted } from 'vue';
  import router from '@/router';
  import { register } from 'swiper/element/bundle';
  import { useAuthStore } from '@/stores/auth';

  register();

  const routerLink = 'HotelPage';
  const store = useAuthStore();

  function onReturnBack() {
    router.push({
      name: routerLink,
    });
  }

  async function onLogout(index: number) {
    await store.logout().then(() => {
      router.push({
        name: 'WelcomePage',
        params: { id: index },
      });
    });
  }

  onMounted(() => {
    window.scrollTo(0, 0);
    document
      .querySelector('.profile-page__cards')
      ?.shadowRoot?.querySelector('.swiper')
      ?.setAttribute('style', 'overflow:visible');
  });
</script>
<style lang="scss" scoped>
  .profile-page {
    flex-direction: column;
    &__header {
      position: fixed;
      z-index: 1002;
      top: 0;
      left: 0;

      display: flex;
      align-items: center;
      justify-content: space-between;

      width: 100%;
      min-height: 75px;
      padding: 0 20px;

      border-bottom-right-radius: 30px;
      border-bottom-left-radius: 30px;
      background: white;
    }

    &__button {
      border: none;

      .button__icon {
        filter: brightness(0) saturate(100%);
      }

      &_large {
        justify-content: start;

        margin-bottom: 5px;
        padding: 0 20px;

        color: black;

        .button__icon {
          filter: brightness(0) saturate(100%);
        }
      }
    }

    &__list {
      margin-bottom: auto;
    }

    &__empty {
      margin: auto 0;

      text-align: center;
    }

    &__clear {
      width: min-content;
      padding: 0 15px;

      img {
        @include filter-gray-black;
      }
    }

    &__cards {
      width: 100%;
      height: 140px;
      margin-bottom: 15px;
    }

    &__card {
      position: relative;

      display: flex;
      overflow: hidden;
      align-items: start;
      flex-direction: column;
      justify-content: space-between;

      width: 40%;
      height: 100%;
      padding: 10px;

      word-wrap: break-word;

      border-radius: 25px;
      background: #f5f5f5;

      font-weight: 600;

      @include toRem(line-height, 19px);

      span {
        width: 100%;

        word-wrap: break-word;
      }
    }

    &__delete {
      margin-bottom: 87px;

      color: var(--red-error);
      background: transparent;
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 50%;
      background: white;

      @include setSquareSize(42px);
    }

    &__title {
      margin: 90px 0 30px;

      font: {
        weight: 600;
        @include toRem(size, 28px);
      }

      @include toRem(line-height, 19px);
    }

    &__subtitle {
      margin-bottom: 8px;

      font: {
        weight: 600;
        @include toRem(size, 18px);
      }

      @include toRem(line-height, 19px);

      &_gray {
        margin-bottom: 20px;

        color: var(--gray-black);

        @include toRem(font-size, 16px);
      }

      &_info {
        margin: auto 0 15px;

        color: var(--gray-black);
      }
    }

    .icon_right {
      position: absolute;
      right: 20px;

      filter: brightness(0) saturate(100%);
    }
  }
</style>
