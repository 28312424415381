const getEnumValues = <T extends Record<string, string>>(
  enumObject: T,
): T[keyof T][] => {
  return Object.values(enumObject) as T[keyof T][];
};

const setHoverBehavior = (className: string): void => {
  document.querySelectorAll(className).forEach(element => {
    element.addEventListener('touchstart', () => {
      element.classList.add('on-hover');
    });
    element.addEventListener('touchend', () => {
      element.classList.remove('on-hover');
    });
  });
};

const setHoverBehaviorArray = (items: HTMLElement[]): void => {
  items.forEach(element => {
    element.addEventListener('touchstart', () => {
      element.classList.add('on-hover');
    });
    element.addEventListener('touchend', () => {
      element.classList.remove('on-hover');
    });
  });
};

function isLocalStorageSupported(): boolean {
  try {
    const testKey = '__storage_test__';
    localStorage.setItem(testKey, 'test');
    localStorage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
}

function isSafari() {
  const ua = navigator.userAgent.toLowerCase();
  const isSafariBrowser =
    ua.includes('safari') &&
    !ua.includes('chrome') &&
    !ua.includes('android') &&
    !ua.includes('yabrowser') &&
    !ua.includes('pwa');

  return isSafariBrowser;
}

function isPWA() {
  return window.matchMedia('(display-mode: standalone)').matches;
}

function reformatDate(date: Date) {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  return `${day}.${month}`;
}

export {
  getEnumValues,
  setHoverBehavior,
  setHoverBehaviorArray,
  isLocalStorageSupported,
  isSafari,
  isPWA,
  reformatDate,
};
