<template>
  <button :class="['button', computedClass]" :disabled="isDisable">
    <div v-if="isLoading" class="button__loader"></div>
    <slot v-else></slot>
    <span v-if="text" class="button__text">{{ text }}</span>
  </button>
</template>

<script lang="ts">
  export default {
    name: 'AppButton',
  };
</script>

<script lang="ts" setup>
  import { computed, onMounted } from 'vue';
  import { setHoverBehavior } from '@/helper';

  const props = withDefaults(
    defineProps<{
      text?: string;
      theme?: string | string[];
      isRound?: boolean;
      isSquare?: boolean;
      isDisable?: boolean;
      isLoading?: boolean;
    }>(),
    {
      text: '',
      theme: '',
      isRound: false,
      isSquare: false,
      isDisable: false,
      isLoading: false,
    },
  );

  const computedClass = computed(() => {
    const baseClass = 'button';
    const squareClass = props.isSquare ? 'button_square' : '';
    const roundClass = props.isRound ? 'button_round' : '';
    const disableClass = props.isDisable ? 'button_disable' : '';
    const themeClasses = Array.isArray(props.theme)
      ? props.theme.map(t => `button_${t}`).join(' ')
      : `button_${props.theme}`;

    return `${baseClass} ${themeClasses} ${squareClass} ${roundClass} ${disableClass}`;
  });

  onMounted(() => setHoverBehavior('.button'));
</script>

<style lang="scss">
  .button {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: $ui-height;

    cursor: pointer;
    transition: all 0.1s ease;

    color: white;
    border: none;
    border-radius: 26px;
    outline: none;
    background: var(--gray-main);

    font-weight: 600;

    gap: 10px;
    @include toRem(line-height, 20px);

    &_active {
      background-color: var(--blue-main);

      &.on-hover {
        background: var(--blue-light);
      }

      .button__icon {
        @include filter-white;
      }
    }

    &__icon {
      @include setSquareSize(20px);
    }

    &_common {
      color: var(--blue-main);
      background-color: var(--blue-bg);
    }

    &_light {
      background: var(--blue-light);
      color: white;

      .button__icon {
        @include filter-white;
      }
    }

    &_gray {
      color: var(--gray-black);
      background-color: #f5f5f5;

      &.on-hover {
        background: var(--gray-main);
      }
    }

    &_round {
      color: var(--blue-main);
      border: 1px solid var(--blue-main);
      border-radius: 50px;

      &.on-hover {
        background: var(--light-gray);
      }
    }

    &_active {
      border: 1px solid var(--blue-main);
    }

    &_inactive {
      color: black;
      border: 1px solid var(--gray-main);
    }

    &_disable {
      border: 1px solid var(--light-gray);

      .button__icon {
        @include filter-white;
      }
    }

    &_square {
      min-width: 52px;
      min-height: 52px;

      @include setSquareSize(52px);
    }

    &__loader {
      display: inline-block;

      box-sizing: border-box;

      animation: rotation 1s linear infinite;

      border: 2px solid white;
      border-bottom-color: transparent;
      border-radius: 50%;

      @include setSquareSize(20px);
    }

    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
</style>
