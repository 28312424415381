<template>
  <div class="app">
    <div class="container">
      <transition name="modal" mode="out-in" :duration="animationDuration">
        <order-bottom-sheet
          v-if="orders.length && isAuth"
          :order="orders.slice(-1)[0]"
        />
      </transition>
      <transition name="popup" mode="out-in" :duration="animationDuration">
        <popup-notification
          v-show="isShowPopup"
          :is-error="isOffline"
          :is-correct="!isOffline"
        >
          {{ popupMessage }}
        </popup-notification>
      </transition>
      <router-view v-slot="{ Component, route }">
        <transition name="page" mode="out-in">
          <component
            :is="Component"
            :key="route.name"
            @pop="changePopupMessage"
          />
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script lang="ts">
  export default {
    name: 'App',
  };
</script>

<script lang="ts" setup>
  import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
  import { useAuthStore } from '@/stores/auth';
  import { animationDuration } from '@/config';
  import { OrderBottomSheet } from '@/pages';
  import { Order } from './models';
  import { useOrderStore } from '@/stores/cart';

  const store = useAuthStore();
  const order = useOrderStore();
  const isAuth = computed(() => store.isAuth);
  const orders = ref<Order[]>([]);
  const height = ref(0);
  const isShowPopup = ref(false);
  const isOffline = ref(false);
  const popupMessage = ref('');
  const timer = ref();

  function setWindowHeight(event?: Event) {
    let vh: number;
    if (!event) vh = window.innerHeight * 0.01;
    else {
      vh = event.target?.height * 0.01;
    }
    return vh;
  }

  async function changePopupMessage(message: string) {
    if (isAuth.value)
      orders.value = await order.getOrders().then(data => {
        return data;
      });
    popupMessage.value = message;
    showPopup();
  }

  function showPopup() {
    clearTimeout(timer.value);
    isShowPopup.value = false;
    timer.value = setTimeout(() => {
      isShowPopup.value = true;
    }, animationDuration);
    timer.value = setTimeout(() => {
      isShowPopup.value = false;
    }, 5000);
  }

  watch(
    () => isAuth.value,
    async () => {
      if (isAuth.value)
        orders.value = await order.getOrders().then(data => {
          return data;
        });
    },
  );

  onMounted(async () => {
    if (!navigator.onLine) {
      isOffline.value = true;
      changePopupMessage('Соединение потеряно...');
    }
    window.addEventListener('offline', () => {
      isOffline.value = true;
      changePopupMessage('Соединение потеряно...');
    });
    window.addEventListener('online', () => {
      isOffline.value = false;
      changePopupMessage('Соединение восстановлено!');
    });
    height.value = setWindowHeight();
    document.documentElement.style.setProperty('--height', `${height.value}px`);
    await store.checkAuth();
    if (isAuth.value)
      orders.value = await order.getOrders().then(data => {
        return data;
      });
  });

  onUnmounted(() => {
    visualViewport?.removeEventListener('resize', setWindowHeight);
  });
</script>

<style lang="scss">
  .app {
    position: relative;

    display: block;
    overflow-x: hidden;

    width: 100vw;
    height: auto;
    min-height: calc(var(--height, 1vh) * 100);

    background: white;

    font: {
      family: 'Proxima Nova', sans-serif;
      weight: 400;
      size: 1rem;
    }
    line-height: 0.9375rem;

    .page-enter-active,
    .page-leave-active {
      transition: opacity 0.15s ease;
    }

    .page-enter-from,
    .page-leave-to {
      opacity: 0;
    }
  }
</style>
