import AppHeader from '@/components/AppHeader.vue';
import DatePicker from '@/components/DatePicker.vue';
import AppBackground from '@/components/AppBackground.vue';
import AppInputPhone from '@/components/AppInputPhone.vue';
import AppInputCode from '@/components/AppInputCode.vue';
import PopupNotification from '@/components/PopupNotification.vue';
import AppSearchBar from './AppSearchBar.vue';

export default [
  AppHeader,
  DatePicker,
  AppBackground,
  AppInputCode,
  AppInputPhone,
  PopupNotification,
  AppSearchBar,
];
