<template>
  <div class="hotel-menu">
    <div class="hotel-menu__header">
      <div class="hotel-menu__logo"></div>
      <div class="hotel-menu__logo_big"></div>
      <app-button
        class="hotel-menu__button_back"
        theme="gray"
        is-square
        @click="onReturnBack"
      >
        <img class="button-icon" alt="" src="@/assets/icons/SVG/plus.svg" />
      </app-button>
    </div>
    <ul class="hotel-menu__list">
      <li
        v-for="item in menu"
        :key="`menu-${item.name}`"
        class="hotel-menu__item"
        @click="openPage(item.link)"
      >
        <div class="hotel-menu__icon">
          <div
            v-if="item.icon === 'notifications'"
            class="hotel-menu__notification"
          />
          <img :src="require(`@/assets/icons/SVG/${item.icon}.svg`)" alt="" />
        </div>
        <span class="hotel-menu__text"> {{ item.name }} </span>
      </li>
    </ul>
    <div class="background">
      <div class="background__gradient_1"></div>
      <div class="background__gradient_2"></div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { menu } from '@/config';
  import router from '@/router';

  async function openPage(link: string) {
    router.push({
      name: link,
    });
  }

  function onReturnBack() {
    router.push({
      name: 'HotelPage',
    });
  }
</script>

<style lang="scss" scoped>
  .hotel-menu {
    &__header {
      display: flex;
      align-items: center;

      width: 100%;
      min-height: 75px;
    }

    &__button_back {
      margin-left: auto;

      .button-icon {
        transform: rotate(45deg);

        filter: brightness(0) saturate(100%);
      }
    }

    &__notification {
      position: absolute;
      z-index: 1002;
      top: 2px;
      right: 2px;

      border-radius: 50%;
      background: #e75252;

      @include setSquareSize(10px);
    }

    &__logo {
      min-height: 50px;

      background: url('@/assets/images/logo.png') no-repeat center / cover;

      @include setSquareSize(50px);

      &_big {
        width: 100px;
        height: 20px;
        margin-left: 12px;

        background: url('@/assets/icons/SVG/logo-hotelkey.svg') no-repeat center /
          cover;
      }
    }

    &__input {
      margin: 10px 0 30px;
    }

    &__list {
      display: flex;
      flex-direction: column;

      padding-top: 58px;

      gap: 15px;
    }

    &__item {
      display: flex;
      align-items: center;
      flex-direction: row;

      width: 100%;

      cursor: pointer;

      gap: 15px;

      &:first-of-type .hotel-menu__icon {
        background: var(--blue-main);

        img {
          @include filter-white;
        }
      }
    }

    &__icon {
      position: relative;

      display: flex;
      align-items: center;
      justify-content: center;

      transition: all 0.2s ease;

      border-radius: 50%;
      background: var(--light-gray);

      @include setSquareSize($ui-height);

      img {
        @include filter-gray-black;
      }
    }

    &__text {
      font: {
        @include toRem(size, 20px);
        weight: 600;
      }

      @include toRem(line-height, 24.4px);
    }

    .background {
      &__gradient_1 {
        position: absolute;
        right: -100px;
        bottom: -100px;

        background: radial-gradient(
          circle,
          rgba(17, 55, 255, 0.15) 15%,
          rgba(0, 0, 0, 0) 75%
        );

        @include setSquareSize(350px);
      }

      &__gradient_2 {
        position: absolute;
        right: 100px;
        bottom: 60px;

        background: radial-gradient(
          circle,
          rgba(243, 128, 47, 0.15) 10%,
          rgba(0, 0, 0, 0) 75%
        );

        @include setSquareSize(180px);
      }
    }
  }
</style>
