<template>
  <label
    :class="['search-bar', { 'search-bar_full': isInputVisible }]"
    for="search-bar"
  >
    <app-input
      v-if="isInputVisible"
      id="search-bar"
      v-model="searchQuery"
      class="search-bar__input"
      placeholder="Поиск"
      data-testid="input"
    />
    <img data-cy="input-phone" alt="" src="@/assets/icons/SVG/search.svg" />
  </label>
</template>

<script lang="ts">
  export default {
    name: 'AppSearchBar',
  };
</script>

<script lang="ts" setup>
  import { ref } from 'vue';
  import AppInput from '@/components/atoms/AppInput.vue';

  defineProps<{
    isInputVisible: boolean;
  }>();

  const searchQuery = ref('');
</script>

<style lang="scss" scoped>
  .search-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: $ui-height;
    height: $ui-height;
    padding: 17px;

    transition: all 0.4s ease;

    border-radius: 50px;
    background-color: var(--light-gray);

    &_full {
      width: 100%;
    }

    &__icon {
      @include setSquareSize(20px);
    }

    &__input {
      width: 100%;
      height: 100%;

      transition: all 0.4s ease;

      font-weight: 700;
      font-style: italic;
    }
  }
</style>
