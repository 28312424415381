import { order } from '@/api';
import { Cart, IService, Order } from '@/models';
import { LocalStorageWrapper } from '@/utils/localStorageWrapper';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useOrderStore = defineStore('order', () => {
  const localStorage = new LocalStorageWrapper();
  const cart = ref<Cart>(localStorage.getItem('cart') || { items: [] });

  function addService(service: IService, amount: number, date?: Date) {
    if (date) cart.value.items.push({ service, amount, meta: { slot: date } });
    else cart.value.items.push({ service, amount });
    localStorage.setItem('cart', cart.value);
  }

  function getCart(): Cart {
    return cart.value;
  }

  async function getFreeTime(id: string, day: Date): Promise<number[]> {
    const { data } = await order.getServiceFreeTime(id, day);
    return data;
  }

  async function getOrders(): Promise<Order[]> {
    const { data } = await order.getOrders();
    return data;
  }

  async function createOrder() {
    return order.createOrder(cart.value).then(result => {
      clearCart();
      return result;
    });
  }

  function editService(newService: IService, newDate: Date) {
    const foundService = cart.value.items.find(({ service }) => {
      if (newService === service) return true;
    });
    if (foundService?.meta?.slot) {
      foundService.meta.slot = newDate;
    }
  }

  function clearCart() {
    cart.value.items = [];
    localStorage.removeItem('cart');
  }

  return {
    cart,
    addService,
    editService,
    getCart,
    getOrders,
    clearCart,
    getFreeTime,
    createOrder,
  };
});
