import { ref } from 'vue';
import { defineStore } from 'pinia';
import { Category, Hotel } from '@/models';
import { hotel } from '@/api';

export const useHotelStore = defineStore('hotel', () => {
  const currentHotel = ref({} as Hotel);

  async function getHotel(slug: string): Promise<Hotel> {
    const { data } = await hotel.getHotel(slug);
    currentHotel.value = data;
    return data;
  }

  async function getHotelCategories(slug: string): Promise<Category[]> {
    const { data } = await hotel.getHotelCategories(slug);
    return data;
  }

  async function getCategoryDetails(slug: string): Promise<Category> {
    const { data } = await hotel.getCategoryDetails(slug);
    return data;
  }
  return {
    hotel,
    getHotel,
    getHotelCategories,
    getCategoryDetails,
  };
});
