<template>
  <auth-template class="code-page">
    <template #default>
      <div class="link_close" @click="onReturnBack">
        <app-button class="button_close">
          <img
            alt=""
            class="icon_close"
            src="@/assets/icons/SVG/arrow-narrow-left.svg"
          />
        </app-button>
      </div>
      <app-input-code
        v-model:code="code"
        :is-correct="isValid"
        :is-error="isError"
        is-small
        class="input-tile"
        @has-error="onUpdateError"
      />
      <div
        :class="[
          'button_resend',
          {
            button_resend_active: isActive,
          },
        ]"
        data-cy="login"
        @click="onSendCode"
      >
        <img
          alt=""
          class="icon_resend"
          src="@/assets/icons/SVG/corner-up-right-icon.svg"
        />
        <p>Повторно отправить код</p>
      </div>
      <p v-if="timeResend > 0" class="text_resend">
        через {{ timeResend }} секунд
      </p>
    </template>
    <template #buttons>
      <app-message
        v-if="isError"
        is-error
        class="code-page__message"
        data-cy="error"
      >
        {{ errorMessage?.message }}
      </app-message>
      <div class="button_send scroll">
        <app-button
          :theme="theme"
          :is-disable="!isValid || isError || isLoading"
          :is-loading="isLoading"
          text="Войти"
          data-cy="check"
          @click="checkCode"
        >
          <img
            alt=""
            class="button__icon"
            src="@/assets/icons/SVG/arrow-circle-broken-left.svg"
          />
        </app-button>
      </div>
      <teleport to=".container">
        <p class="code-page__button_bottom">Не получили SMS?</p>
        <p
          class="code-page__button_phone"
          data-cy="switch"
          @click="onSwitchMethod"
        >
          Получить звонок с кодом
        </p>
      </teleport>
    </template>
  </auth-template>
</template>

<script lang="ts" setup>
  import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
  import { AxiosError } from 'axios';
  import router from '@/router';
  import { useAuthStore } from '@/stores/auth';
  import { RefreshResponse } from '@/models';
  import { AuthTemplate } from '@/pages';
  import { LocalStorageWrapper } from '@/utils/localStorageWrapper';

  const localStorage = new LocalStorageWrapper();
  const store = useAuthStore();
  const code = ref('');
  const timeResend = ref(60);
  const timer = ref<number | undefined>(undefined);
  const isActive = ref(false);
  const isError = ref(false);
  const isValid = ref(false);
  const isLoading = ref(false);
  const errorMessage = ref<AxiosError>();

  const theme = computed(() => {
    return isValid.value && !isError.value ? 'active' : '';
  });

  async function startTimer(): Promise<void> {
    timer.value = setInterval(() => {
      if (--timeResend.value === 0) {
        stopTimer();
        changeResend();
      }
    }, 1000);
  }

  function changeResend(): void {
    isActive.value =
      !isActive.value &&
      ![429].includes(errorMessage.value?.response?.status as number);
  }

  function stopTimer(): void {
    clearTimeout(timer.value);
  }

  function onUpdateError(value: boolean): void {
    isError.value = value;
  }

  async function onSendCode(): Promise<void> {
    isLoading.value = true;
    if (isActive.value) {
      await store
        .sendSmsCode({
          login: localStorage.getItem('phone') as string,
        })
        .then(changeResend)
        .then(() => {
          timeResend.value = 60;
        })
        .then(startTimer)
        .catch((error: any) => {
          isError.value = true;
          changeResend();
          stopTimer();
          error.message =
            'Вы истратили все попытки, попробуйте авторизоваться позже';
          errorMessage.value = error;
        })
        .finally(() => {
          isLoading.value = false;
        });
    }
  }

  async function checkCode(): Promise<void> {
    isLoading.value = true;
    await store
      .checkCode({
        login: localStorage.getItem('phone') as string,
        password: code.value,
      })
      .then(() => {
        router.push({
          name: 'WelcomePage',
        });
      })
      .catch((error: any) => {
        const { status } = error.response;
        isError.value = true;
        errorMessage.value = error;
        if ([409].includes(status)) {
          error.message = 'Неправильный код!';
        } else if ([429].includes(status)) {
          error.message =
            'Вы истратили все попытки, попробуйте авторизоваться позже';
          changeResend();
        } else error.message = 'Срок действия кода истек!';
      })
      .finally(() => {
        isLoading.value = false;
      });
  }

  async function onReturnBack(): Promise<void> {
    await store.logout();
    await router.push({
      name: 'WelcomePage',
    });
  }

  async function onSwitchMethod(): Promise<void> {
    const phone = localStorage.getItem('phone') as string;
    isLoading.value = true;
    if (phone)
      await store
        .sendCallCode({
          login: phone,
        })
        .then(() => {
          router.push({
            name: 'CodePhonePage',
          });
        })
        .catch((error: any) => {
          isError.value = true;
          error.message =
            'Вы истратили все попытки, попробуйте авторизоваться позже';
          errorMessage.value = error;
        })
        .finally(() => {
          isLoading.value = false;
        });
  }

  onMounted(() => {
    startTimer();
    if (!navigator.onLine) {
      isLoading.value = true;
      isError.value = true;
    }
    window.addEventListener('offline', () => {
      isLoading.value = true;
      isError.value = true;
    });
    window.addEventListener('online', () => {
      isLoading.value = false;
      isError.value = false;
    });
  });
  onUnmounted(stopTimer);

  watch(code, () => {
    if (code.value.length === 4) {
      isValid.value = true;
    } else {
      isValid.value = false;
    }
  });
</script>

<style lang="scss" scoped>
  .code-page {
    &__button {
      &_bottom {
        position: absolute;
        bottom: 50px;
        left: 20px;

        width: calc(100% - 40px);
        margin-top: 30px;

        text-align: center;

        font-weight: 600;

        @include toRem(line-height, 22px);
      }

      &_phone {
        position: absolute;
        z-index: 1001;
        bottom: 30px;
        left: 20px;

        width: calc(100% - 40px);
        margin-top: 5px;

        text-align: center;

        color: var(--blue-main);

        font-weight: 700;
      }
    }

    &__message {
      position: absolute;

      margin-top: 290px;
    }
  }
</style>
